import React from "react"
import { graphql } from "gatsby"

const Page = ({ data }) => {
  
  return (
    <div id='333' dangerouslySetInnerHTML={{__html: data.allDatoCmsMainPage.nodes[0].mainIframeNode.internal.content}}></div>
  )

}


export function Head({ data } ) {
  return (
    <>
        {/* First we itereate thgough site specific tags, beacsue later ones will have precedance according to Gatsby docs */}

        {data.datoCmsSeoMetaTags.tags.map((tag, i) => {
        switch (tag.tagName) {
          case 'meta':
            return (
              <meta id={ [ 'meta' , tag.attributes.name , tag.attributes.property ].join('') }
                    name={tag.attributes.name} key={tag.attributes.name} property={tag.attributes.property} content={tag.attributes.content}/>
              )
          case 'title': return ''
          default: console.log("new meta component added"); return '';
          }
        })}


      {data.allDatoCmsFaviconMetaTags.nodes[0].tags.map((tag, i) => {
        switch (tag.tagName) {
          case 'link':
            return (<link id={ ['link' , tag.attributes.rel , tag.attributes.sizes].join('') } href={tag.attributes.href} key={tag.attributes.href} rel={tag.attributes.rel} sizes={tag.attributes.sizes} />)
          case 'meta':
            return (<meta id={ ['meta' , tag.attributes.name].join('') } name={tag.attributes.name} key={tag.attributes.name} content={tag.attributes.content} />)
          default: console.log("new meta component added"); return '';
          }
        })}
      <title>{data.allDatoCmsMainPage.nodes[0].seoHeaders.title}</title>
      <meta name="description" content={data.allDatoCmsMainPage.nodes[0].seoHeaders.description} />
      <link id="canonical" rel="canonical" href={data.allDatoCmsMainPage.nodes[0].canonicalUrl} />


    </>
  )
}

export const query = graphql`
query {
  allDatoCmsMainPage {
    nodes {
      seoHeaders {
        description
        title
        twitterCard
        image {
          width
          url
          isImage
          height
          gatsbyImageData
          format
          mimeType
          size
        }
      }
      id
      locale
      parent {
        id
      }
      canonicalUrl
      mainIframeNode {
        id
        internal {
          content
        }
      }
    }
  }
  allDatoCmsFaviconMetaTags {
    nodes {
      id
      tags
    }
  }
  datoCmsSeoMetaTags {
    tags
  }
}
`

export default Page

